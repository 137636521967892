<template>
  <v-container :class="clienteComponent ? 'px-3' : ''">
    <v-card :flat="clienteComponent ? true : false">
      <v-card-title
        v-if="!clienteComponent"
        :class="
          clienteComponent
            ? ' text-h5 font-weight-bold pa-3'
            : 'accent text-h5 font-weight-bold pa-3'
        "
      >
        <v-icon color="primary" left>mdi-toolbox-outline</v-icon>
        Atividades Colaborador
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider v-if="!clienteComponent"></v-divider>
      <v-card-text>
        <v-card-title>
          <v-text-field
            style="max-width: 200px;"
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            clearable
            dense
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-col class="pa-1 pt-6" cols="12" md="2">
            <DataField label="Data Início" :data_sync.sync="inicio" />
          </v-col>
          <v-col class="pa-1 pt-6 " cols="12" md="2">
            <DataField label="Data Final" :data_sync.sync="fim" />
          </v-col>
          <v-autocomplete
            style="max-width: 200px;"
            v-model="selectedColaborador"
            :items="equipes"
            :loading="loading"
            :search-input.sync="searchColaborador"
            clearable
            @input="buscaCliente(selectedColaborador, inicio, fim)"
            dense
            solo
            flat
            hide-details
            hide-no-data
            outlined
            class="mx-6 my-0"
            item-text="nome"
            item-value="id"
            label="Colaborador"
            prepend-inner-icon="mdi-account-group"
            return-object
          >
          </v-autocomplete>

          <v-btn
            color="primary"
            class="white--text "
            :disabled="!listaAtividades.length"
            @click="gerarpdf"
          >
            <v-icon left>mdi-file-pdf-box</v-icon>
            PDF
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaAtividades"
            :items-per-page="10"
            :sort-desc="true"
            class="data-tables data-tables__row-click"
          >
          </v-data-table>
        </v-card-text>
      </v-card-text>

      <PdfDialogComp
        v-if="pdfDialog"
        :pdfDialog.sync="pdfDialog"
        :pdfData="pdfData"
        title="RELATÓRIO DURAÇÃO DAS 
ATIVIDADES"
      />
    </v-card>
  </v-container>
</template>

<script>
import { fetchTempoAtividadesColaboradores } from "@/api/gestor/relatorios/atividades_clientes.js";
import { fetchEmpresa } from "@/api/gestor/empresa.js";
const pdfGenerator = () =>
  import(/* webpackChunkName: "relatorio" */ "@/helpers/pdf/geral.js");
import { parseISO, format, startOfMonth, lastDayOfMonth } from "date-fns";
import { fetchEquipe } from "@/api/gestor/empresa_equipe.js";
export default {
  name: "RelatoriosAtividadesPorColaborador",

  props: {
    cliente_id: {
      type: [Number, String],
    },
    clienteComponent: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    PdfDialogComp: () => import("@/components/PdfDialogComp.vue"),
    DataField: () => import("@/components/fields/DataField.vue"),
  },

  data() {
    return {
      search: "",
      searchColaborador: "",
      selectedColaborador: null,
      dialogAddAtividade: false,
      dialogEditAtividade: false,
      loading: false,
      listaAtividades: [],
      equipes: [],
      inicio: format(startOfMonth(new Date()), "yyyy-MM-dd"),
      fim: format(lastDayOfMonth(new Date()), "yyyy-MM-dd"),
      dataCalendario: format(new Date(), "yyyy-MM"),
      edit: false,
      atividadeSelected: null,
      empresa: {},
      pdfData: null,
      pdfDialog: false,

      menu: false,
    };
  },
  watch: {
    inicio() {
      if (this.inicio) {
        this.buscaCliente(
          this.selectedColaborador,
          format(parseISO(this.inicio), "yyyy-MM-dd"),
          format(parseISO(this.fim), "yyyy-MM-dd")
        );
      }
    },
    fim() {
      if (this.fim) {
        this.buscaCliente(
          this.selectedColaborador,
          format(parseISO(this.inicio), "yyyy-MM-dd"),
          format(parseISO(this.fim), "yyyy-MM-dd")
        );
      }
    },
  },

  computed: {
    headers() {
      return [
        {
          text: "Nome",
          width: "200px",
          value: "nome",
        },
        {
          text: "Total",
          width: "200px",
          value: "total",
        },
      ];
    },
  },

  methods: {
    buscaCliente(item1, item2, item3) {
      if (item1 && item2 && item3) {
        this.listaAtividades = [];
        this.getAtividades(
          `?colaborador_id=${item1.id}&inicio=${item2}&fim=${item3}`
        );
      } else if (item2 && item3) {
        this.listaAtividades = [];
        this.getAtividades(`?inicio=${item2}&fim=${item3}`);
      } else if (item1) {
        this.listaAtividades = [];
        this.getAtividades(
          `?colaborador_id=${item1.id}&inicio=${item2}&fim=${item3}`
        );
      }
    },
    getEquipes() {
      this.loading = true;
      fetchEquipe()
        .then((response) => {
          this.equipes = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    zeroPad(num) {
      let str = String(num);
      if (str.length < 2) {
        return "0" + str;
      }

      return str;
    },
    totalTimeString(timeStrings) {
      let totals = timeStrings.reduce(
        function(a, timeString) {
          let parts = timeString.split(":");
          let temp;
          if (parts.length > 0) {
            temp = Number(parts.pop()) + a.seconds;
            a.seconds = temp % 60;
            if (parts.length > 0) {
              temp = Number(parts.pop()) + a.minutes + (temp - a.seconds) / 60;
              a.minutes = temp % 60;
              a.hours = a.hours + (temp - a.minutes) / 60;
              if (parts.length > 0) {
                a.hours += Number(parts.pop());
              }
            }
          }

          return a;
        },
        {
          hours: 0,
          minutes: 0,
          seconds: 0,
        }
      );

      // returned string will be HH(H+):mm:ss
      return [
        this.zeroPad(totals.hours),
        this.zeroPad(totals.minutes),
        this.zeroPad(totals.seconds),
      ].join(":");
    },

    getAtividades(filtro) {
      this.loading = true;
      if (filtro) {
        return fetchTempoAtividadesColaboradores(filtro)
          .then((response) => {
            this.listaAtividades = response;
            this.listaAtividades.forEach((colaborador) => {
              if (colaborador.historico.length) {
                let total_total_trabalhado = "";
                let total_trabalhado = "";
                colaborador.historico.forEach((historico_tempo) => {
                  total_trabalhado = this.totalTimeString([
                    total_trabalhado,
                    historico_tempo.total ? historico_tempo.total : "00:00:00",
                  ]);
                });
                total_total_trabalhado = this.totalTimeString([
                  total_total_trabalhado,
                  total_trabalhado,
                ]);
                colaborador.total = total_total_trabalhado;
              } else {
                colaborador.total = "00:00:00";
              }
            });
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        return fetchTempoAtividadesColaboradores()
          .then((response) => {
            this.listaAtividades = response;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    pdfContent() {
      let content = [];
      let count = 0;
      let objeto_cabecario = {
        margin: [0, 0, 0, 10],
        fontSize: 8,
        columns: [
          {
            image: this.empresa.logo64 ? this.empresa.logo64 : "",
            width: 50,
            alignment: "left",
            margin: [0, 0, 0, 25],
          },
          {
            text: "OLE DESIGNER & COMUNICAÇÃO",
            alignment: "left",
          },
          {
            text:
              "CRUZ E BETTO PRESTADORA DE SERVICOS EM DESIGN LTDA | CNPJ: 08.004.795/0001-18 | Telefone" +
              "(45) 3025-7263 | Endereço Rua Engenheiro Rebouças, 434 | Bairro: Centro | CEP: 85851-190 | Cidade: Foz" +
              "do Iguaçu | Estado: PR",
            alignment: "justify",
          },
        ],
      };

      content.splice(count, 0, objeto_cabecario);
      count += 1;

      let objeto_cabecario2 = {
        margin: [0, 0, 0, 10],
        fontSize: 8,
        columns: [
          {
            text: "RELATÓRIO DURAÇÃO DAS ATIVIDADES POR COLABORADORES",
            fontSize: 16,
            bold: true,
            alignment: "center",
          },
        ],
      };

      content.splice(count, 0, objeto_cabecario2);
      count += 1;

      let objeto_cabecario3 = {
        fontSize: 8,
        margin: [0, 10, 0, 10],
        columns: [
          {
            text:
              "De: " +
              format(
                startOfMonth(new Date(parseISO(this.inicio))),
                "dd/MM/yyyy"
              ),
            bold: true,
            fontSize: 12,
            alignment: "right",
          },
          {
            text:
              "Até: " +
              format(
                lastDayOfMonth(new Date(parseISO(this.fim))),
                "dd/MM/yyyy"
              ),
            bold: true,
            fontSize: 12,
            alignment: "left",
          },
        ],
      };
      content.splice(count, 0, objeto_cabecario3);
      count += 1;
      let tabela_corpo = [];
      let tabela_header = [];

      for (let count = 0; count < 2; count++) {
        let line_header = ["NOME", "TOTAL"];
        let obj = {
          text: line_header[count],
          colSpan: 1,
          fillColor: "#607D8B",
          color: "white",
          bold: true,
          fontSize: 8,
          alignment: "center",
        };

        tabela_header.splice(count, 0, obj);
      }
      tabela_corpo.push(tabela_header);

      this.listaAtividades.forEach((colaborador) => {
        if (colaborador.historico.length) {
          let total_total_trabalhado = "";
          let total_trabalhado = "";
          colaborador.historico.forEach((historico_tempo) => {
            total_trabalhado = this.totalTimeString([
              total_trabalhado,
              historico_tempo.total ? historico_tempo.total : "00:00:00",
            ]);
          });
          total_total_trabalhado = this.totalTimeString([
            total_total_trabalhado,
            total_trabalhado,
          ]);

          let body_line = [
            {
              text: colaborador.nome,
              bold: true,
              fontSize: 10,
              alignment: "start",
            },
            {
              text: total_total_trabalhado,
              bold: true,
              fontSize: 10,
              alignment: "start",
            },
          ];
          //
          tabela_corpo.push(body_line);
        } else {
          let body_line = [
            {
              text: colaborador.nome,

              fontSize: 8,
              alignment: "start",
            },
            {
              text: "00:00:00",

              fontSize: 8,
              alignment: "start",
            },
          ];
          //
          tabela_corpo.push(body_line);
        }
      });
      let obj_tempo = {
        margin: [0, 0, 0, 10],
        table: {
          widths: ["*", "*"],
          headerRows: 1,
          body: tabela_corpo,
        },
        layout: {
          hLineWidth: function(i, node) {
            return i <= 1 || i === node.table.body.length ? 1 : 0;
          },
          vLineWidth: function(i, node) {
            return i === 0 || i === node.table.widths.length ? 0 : 1;
          },
          hLineColor: function(i, node) {
            return i === 0 || i === node.table.body.length
              ? "#CFD8DC"
              : "#CFD8DC";
          },
          vLineColor: function(i, node) {
            return i === 0 || i === node.table.widths.length
              ? "#CFD8DC"
              : "#CFD8DC";
          },
          fillColor: function(rowIndex) {
            return rowIndex > 0 && rowIndex % 2 === 0 ? "#E0E0E0" : null;
          },
        },
      };

      content.splice(count, 0, obj_tempo);
      count += 1;

      return content;
    },

    async gerarpdf() {
      try {
        this.loading = true;
        this.$Progress.start();
        await this.getEmpresa();

        await pdfGenerator()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.pdfContent()).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loading = false;
            this.$Progress.finish();
          });
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$Progress.fail();
      }
    },
    getEmpresa() {
      this.loading = true;
      return fetchEmpresa()
        .then((response) => {
          if (response) {
            this.empresa = { ...response };
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  async mounted() {
    await this.getEquipes();
    await this.getAtividades(
      `?inicio=${format(parseISO(this.inicio), "yyyy-MM-dd")}&fim=${format(
        parseISO(this.fim),
        "yyyy-MM-dd"
      )}`
    );
  },
};
</script>

<style lang="scss"></style>
