import api from "../../axios_service.js";

export async function fetchAtividadesClientesGeral(filtros) {
  const response = await api.get(
    `/gestor/relatorios/tempo-atividades-colaboradores${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function fetchTempoAtividadesColaboradores(filtros) {
  const response = await api.get(
    `/gestor/relatorios/tempo-colaboradores${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function fetchAniversariosEquipeClientes(filtros) {
  const response = await api.get(
    `/gestor/relatorios/aniversarios-colaboradores${filtros ? filtros : ""}`
  );
  return response.data.result;
}